import { XS } from '@general/constants/general'
import { createSlice } from '@reduxjs/toolkit'
export const windowWidth = createSlice({
    name: 'gridSize',
    initialState: {
        value: XS,
    },
    reducers: {
        setGridSize: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setGridSize } = windowWidth.actions
export const gridSizeReducer = windowWidth.reducer