import { createSlice } from '@reduxjs/toolkit'
export const displayMainMenu = createSlice({
    name: 'opened',
    initialState: {
        value: false,
    },
    reducers: {
        openMenu: (state, action) => {
            state.value = action.payload
        },
        toggleMenu: (state) => {
            state.value = !state.value
        },
    },
})

export const { openMenu, toggleMenu} = displayMainMenu.actions

export default displayMainMenu.reducer