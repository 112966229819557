import { createSlice } from '@reduxjs/toolkit'
import { fallbackLang } from '@general/i18n/settings'
export const langSlice = createSlice({
    name: 'lang',
    initialState: {
        value: fallbackLang,
    },
    reducers: {
        setLang: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setLang } = langSlice.actions

export default langSlice.reducer