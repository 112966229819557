import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_COUNTRY_ISO } from '@general/constants/general'
export const countryIsoSlice = createSlice({
    name: 'country_iso',
    initialState: {
        value: DEFAULT_COUNTRY_ISO,
    },
    reducers: {
        setCountryIso: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setCountryIso } = countryIsoSlice.actions

export const countryIsoReducer = countryIsoSlice.reducer