import { createSlice } from '@reduxjs/toolkit'
export const isMobile = createSlice({
    name: 'isMobile',
    initialState: {
        value: false,
    },
    reducers: {
        setIsMobile: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setIsMobile} = isMobile.actions

export default isMobile.reducer