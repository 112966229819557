import { configureStore } from '@reduxjs/toolkit'
import langReducer from '@general/redux/slices/lang'
import displayMainMenu from '@general/redux/slices/mainMenu';
import isMobile from '@general/redux/slices/userAgent';
import { gridSizeReducer } from '@general/redux/slices/width';
import { countryIsoReducer } from '@general/redux/slices/countryIso';
export const store = configureStore({
  reducer: {
    lang: langReducer,
    displayMainMenu: displayMainMenu,
    isMobile: isMobile,
    gridSize: gridSizeReducer,
    countryIso: countryIsoReducer
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;